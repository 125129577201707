body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0 ;
  width: 95%;
  height: 100%;
  font-size: 14x; /* Set your desired font size */
  border-radius: 10px; /* Adjust the border radius for rounded corners */
  color: #333 ; /* Set text color to white for readability on the background */
  padding: 20px; /* Add padding for better readability and spacing */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container {
  display: flex;
}

/* Style the panel section */
.panel {
  width: 100%;
  height: 10%;

}

/* Style the main content section */
.main {
  width: 90%;
  height: 100%;
}

